import { useState, useRef, useEffect } from 'react';

export type StorageKey =
  | 'drag-and-drop-helper-acknowledged'
  | 'wavealyzer-ids-dismissed'
  | 'sound-file-interest'
  | 'sound-file-previously-activated'
  | 'sound-file-intro'
  | 'release-nav-prompt'
  | 'yt-content-id-modal'
  | 'lyrics-resubmit-modal'
  | 'lyrics-intro-modal'
  | 'royalty-prefilled'
  | 'author-warning-modal'
  | 'beatport-offer-modal'
  | 'previously-released-modal'
  | 'beatport-banner-view'
  | 'referral-code'
  | 'artwork-rights-modal'
  | 'audio-file-rights-modal'
  | 'sider-collapsed';

interface UseLocalStorageReturn<R = string> {
  value: R | null;
  setValue: (value: string) => void;
}

export const useLocalStorage = <R = string>(
  key: StorageKey,
  parse?: (value: string | null) => R | null
): UseLocalStorageReturn<R> => {
  const mounted = useRef(true);
  const [storageValue, setStorageValue] = useState<string | null>(
    localStorage.getItem(key)
  );

  useEffect(
    () => () => {
      mounted.current = false;
    },
    []
  );

  const setValue = (value: string): void => {
    if (!mounted.current) {
      return;
    }
    localStorage.setItem(key, value);
    setStorageValue(value);
  };

  return {
    value: (typeof parse === 'function'
      ? parse(storageValue)
      : storageValue) as R,
    setValue,
  };
};
