import values from 'lodash/values';
import { ReleaseCombinedStatus } from 'models/Release';
import dayjs from 'dayjs';
import { SubscriptionPlan } from 'models/Subscription';

/**
 * USER CONSTANTS
 */

export const PASSWORD_LIMITS = {
  maximum: 256,
  minimum: 8,
};

/**
 * RELEASE SUBMISSION CONSTANTS
 */

export const releaseNpsOptions = [
  {
    labelKey: 'release.confirmationModalNpsReleasingMusic',
    value: 'RELEASING_MUSIC',
  },
  {
    labelKey: 'release.confirmationModalNpsCustomerSupport',
    value: 'CUSTOMER_SUPPORT',
  },
  {
    labelKey: 'release.confirmationModalNpsPerformanceAndTrend',
    value: 'PERFORMANCE_TREND',
  },
  {
    labelKey: 'release.confirmationModalNpsManagingTracks',
    value: 'MANAGING_TRACKS',
  },
  {
    labelKey: 'release.confirmationModalNpsWithdraw',
    value: 'WITHDRAWALS',
  },
  {
    labelKey: 'release.confirmationModalNpsUsability',
    value: 'USABILITY',
  },
  { labelKey: 'release.confirmationModalNpsDesign', value: 'DESIGN' },
  { labelKey: 'release.confirmationModalNpsGuide', value: 'GUIDES' },
  { labelKey: 'release.confirmationModalNpsOther', value: 'OTHER' },
];

export enum UploadType {
  TRACK,
  ARTWORK,
  FILES,
}

export const minimumArtworkPixelSize = 640;
export const acceptedArtworkFormat = 'image/jpg,image/jpeg,image/png';
export const maximumArtworkPixelSize = 16383;
export const maximumBioPageImagePixelSize = 15000;
export const maximumProfileImagePixelSize = 15000;
export const maximumFileSize = 52428800;

export enum AUTHOR_ROLE {
  COMPOSER = '401',
  EDITOR = '402',
  ARRANGER = '403',
  SONG_WRITER = '404',
  PRODUCER = '405',
  LYRICIST = '406',
  PUBLISHER = '430',
  ADAPTOR = '441',
}

export enum WORK_AUTHOR_ROLE {
  ADAPTOR = '441',
  COMPOSER = '401',
  ARRANGER = '403',
  LYRICIST = '406',
  PUBLISHER = '430',
}

export enum ARTIST_ROLE {
  MAIN_ARTIST = '131',
  FEATURING = '501',
  WITH = '502',
  VERSUS = '503',
  MEETS = '504',
  PRESENTS = '505',
  REMIXER = '506',
}

export enum PARTICIPANT_ROLE {
  INSTRUMENT = '200',
}

export const ELECTRONIC_KEY_BASE = '0104';
export const CLASSICAL_KEY_BASE = '020';
export const CLASSICAL_CROSS_OVER = '0202';
export const ORIGINAL_MOTION_PICTURE_SOUNDTRACK_KEY_BASE = '0101040';
export const MUSICAL_KEY = '0101040300';
export const POP_ROLE_PREFIX = '13';
export const REQUIRED_CLASSICAL_ROLE_PREFIX = ['10', '11', '12', '2'];

// TODO: Adapt selection option type to include disabled and move this to gema-genres.json
export const DISABLED_GEMA_GENRES = [
  'BUEHNENMUSIK',
  'CHORAL',
  'CHORALBEARBEITUNG',
  'CHORALFANTASIE',
  'CHORALKANTATE',
  'CHORALMOTETTE',
  'CHORALVORSPIEL',
  'CHORWERK',
  'CHORLIED',
  'CHORWERK',
  'E_MUSIK',
  'EXPERIMENTELLE_MUSIK',
  'GEISTLICHE_MUSIK',
  'GEISTLICHES_KONZERT',
  'GEISTLICHES_LIED',
  'INSTRUMENTALMUSIK',
  'JAZZ',
  'JAZZ_ROCK',
  'KAMMERMUSIK',
  'KANTATE',
  'KIRCHENMUSIK',
  'KIRCHENSONATE',
  'MADRIGAL',
  'MESSE',
  'MOTETTE',
  'MUSICAL',
  'OPER',
  'OPERETTE',
  'ORATORIUM',
  'ORCHESTERWERK',
  'PASSION',
  'REQUIEM',
  'REZITATIV',
  'ROMANZE',
  'VOKALMUSIK',
  'WALZER',
  'WALZERLIED',
];

export const DISABLED_GEMA_ROLES = [WORK_AUTHOR_ROLE.PUBLISHER];

export const TRADITIONAL_AUTHOR = 'Traditional';

export const PUBLIC_DOMAIN_IPI_NUMBER = '00039657154';

export enum AvailableLocale {
  EN = 'en',
  DE = 'de',
}

export const fileTypeMap = {
  ['audio/wav' as string]: UploadType.TRACK,
  ['audio/x-wav' as string]: UploadType.TRACK,
  ['image/jpg' as string]: UploadType.ARTWORK,
  ['image/jpeg' as string]: UploadType.ARTWORK,
};

export const FILE_FORMAT_SUPPORT = {
  AUDIO: 'WAV',
  IMAGE: 'JPG',
};

/*
 * a list of should be excluded contributor roles
 * for the participants list given by Marco
 */
export const excludedContributorRoles = [
  ...values(AUTHOR_ROLE),
  ...values(ARTIST_ROLE),
];

/**
 * INTERCOM ARTICLES
 */
export const intercomStyleGuide = {
  ['de' as string]: 'https://intercom.help/music-hub/de/articles/4647795-musichub-styleguide',
  ['en' as string]: 'https://intercom.help/music-hub/en/articles/4647795-musichub-style-guide',
};

export const youtubeTutorialGuide = {
  ['de' as string]: 'https://intercom.help/music-hub/de/articles/5352440-musichub-release-guide-tutorial',
  ['en' as string]: 'https://intercom.help/music-hub/en/articles/5352440-musichub-release-guide-tutorial',
};

export const explainedVersionsGuide = {
  ['de' as string]: 'https://intercom.help/music-hub/de/articles/4791495-versionen-erklart',
  ['en' as string]: 'https://intercom.help/music-hub/en/articles/4791495-versions-explaine',
};

export const artworkConsiderGuide = {
  ['de' as string]: 'https://intercom.help/music-hub/de/articles/4574756-was-muss-ich-bei-meinem-artwork-beachten',
  ['en' as string]: 'https://intercom.help/music-hub/en/articles/4574756-what-do-i-have-to-consider-with-my-artwork',
};

export const prepareAudioGuide = {
  ['de' as string]: 'https://intercom.help/music-hub/de/articles/4711094-was-muss-ich-beim-hochladen-meiner-audiodateien-beachten',
  ['en' as string]: 'https://intercom.help/music-hub/en/articles/4711094-how-to-prepare-your-audio-files-for-your-release',
};

export const explainArtistRolesGuide = {
  ['de' as string]: 'https://intercom.help/music-hub/de/articles/4799534-hervorgehobene-kunstler-innen-mitwirkende',
  ['en' as string]: 'https://intercom.help/music-hub/en/articles/4799534-main-artists-contributors-explained',
};

export const artworkCopyrightGuide = {
  ['de' as string]: 'https://intercom.help/music-hub/de/articles/4818473-urheberrecht-aufnahmerechte-copyrights',
  ['en' as string]: 'https://intercom.help/music-hub/en/articles/4818473-copyright-rights-ownership',
};

export const classicalVersionGuide = {
  ['de' as string]: 'https://intercom.help/music-hub/de/articles/4825974-quick-style-guide-fur-klassische-musik',
  ['en' as string]: 'https://intercom.help/music-hub/en/articles/4825974-style-guide-for-classical-releases',
};

export const coverVersionGuide = {
  ['de' as string]: 'https://intercom.help/music-hub/de/articles/6930344-wie-man-eine-cover-version-veroffentlicht',
  ['en' as string]: 'https://intercom.help/music-hub/en/articles/6930344-how-to-release-a-cover-version',
};

export const soundFileRoyaltiesGuide = {
  ['de' as string]: 'https://intercom.help/music-hub/de/articles/5320159-soundfile-upload-und-tantieme',
  ['en' as string]: 'https://intercom.help/music-hub/en/articles/5320159-soundfile-upload-and-royalties',
};

export const reviewEditsGuide = {
  ['de' as string]: 'https://intercom.help/music-hub/de/articles/4622688-kann-ich-mein-release-im-nachgang-noch-bearbeiten',
  ['en' as string]: 'https://intercom.help/music-hub/en/articles/4622688-can-i-still-edit-my-release-even-if-i-have-already-submitted-it-or-it-has-already-been-published',
};

export const howToTikTokGuide = {
  ['de' as string]: 'https://intercom.help/music-hub/de/articles/5435568-wie-du-deine-musik-auf-tiktok-bringst',
  ['en' as string]: 'https://intercom.help/music-hub/en/articles/5435568-how-to-distribute-your-music-to-tiktok',
};

export const youtubeContentMusicDiffs = {
  ['de' as string]: 'https://intercom.help/music-hub/de/articles/5985524-was-ist-der-unterschied-zwischen-youtube-music-und-youtube-content-id',
  ['en' as string]: 'https://intercom.help/music-hub/en/articles/5985524-what-s-the-difference-between-youtube-music-and-youtube-content-id',
};

export const otherDspsTypeform = {
  ['de' as string]: 'https://music-hub.typeform.com/to/mxw3ZL2n',
  ['en' as string]: 'https://music-hub.typeform.com/to/U54zDngt',
};

export const soundfileUploadGuidelines = {
  ['de' as string]: 'https://intercom.help/music-hub/de/articles/5362189-wie-funktioniert-der-soundfile-upload-uber-musichub',
  ['en' as string]: 'https://intercom.help/music-hub/en/articles/5362189-soundfile-upload-how-it-works-via-musichub',
};

export const worksDeclarationGuidelines = {
  ['de' as string]: 'https://intercom.help/music-hub/de/articles/5871355-gema-werkanmeldung-und-tantiemen-einfach-erklart',
  ['en' as string]: 'https://intercom.help/music-hub/en/articles/5871355-gema-work-declaration-and-royalties-explained',
};

export const findIpiNumberGuide = {
  ['de' as string]: 'https://intercom.help/music-hub/de/articles/5950535-wie-kann-ich-meine-ipi-nummer-finden',
  ['en' as string]: 'https://intercom.help/music-hub/en/articles/5950535-where-can-i-find-my-ipi-number',
};

export const missingStoreInfo = {
  ['de' as string]: 'https://intercom.help/music-hub/de/articles/5991695-musichub-statistiken',
  ['en' as string]: 'https://intercom.help/music-hub/en/articles/5991695-musichub-stats',
};

export const youtubeContentClaimsInfo = {
  ['de' as string]: 'https://intercom.help/music-hub/de/articles/5904020-youtube-content-id-claims',
  ['en' as string]: 'https://intercom.help/music-hub/en/articles/5904020-youtube-content-id-claims',
};

export const smartlinksInfo = {
  ['de' as string]: 'https://intercom.help/music-hub/de/articles/6310065-was-sind-smart-links-und-wie-funktionieren-sie',
  ['en' as string]: 'https://intercom.help/music-hub/en/articles/6310065-what-are-smart-links-and-how-do-they-work',
};

export const coverArtworkTips = {
  ['de' as string]: 'https://intercom.help/music-hub/de/articles/5306630-tipps-und-inspiration-fur-dein-cover-artwork',
  ['en' as string]: 'https://intercom.help/music-hub/en/articles/5306630-good-practices-for-creating-your-artwork',
};

export const lyricsStyleGuide = {
  ['de' as string]: 'https://intercom.help/music-hub/de/articles/6435713-lyrics-styleguide',
  ['en' as string]: 'https://intercom.help/music-hub/en/articles/6435713-lyrics-style-guide',
};

export const lyricsDistributionList = {
  ['de' as string]: 'https://intercom.help/music-hub/de/articles/6515516-lyrics-distributionsliste',
  ['en' as string]: 'https://intercom.help/music-hub/en/articles/6515516-lyrics-distribution-list',
};

export const uploadCertificateArticle = {
  ['de' as string]: 'https://intercom.help/music-hub/de/articles/7907935-das-musichub-upload-zertifikat',
  ['en' as string]: 'https://intercom.help/music-hub/en/articles/7907935-the-musichub-upload-certificate',
};

export const publishedReleasesToBeatport = {
  ['de' as string]: 'https://intercom.help/music-hub/de/articles/6078331-wie-kann-ich-bereits-veroffentlichte-releases-an-neue-streaming-dienste-und-plattformen-senden',
  ['en' as string]: 'https://intercom.help/music-hub/en/articles/6078331-how-can-i-send-already-published-releases-to-new-streaming-services-and-platforms',
};

/**
 * GEMA LOGIN
 */
export const gemaRegisterUrl = 'https://www.gema.de/portal/app/register';
export const gemaForgottenPasswordUrl =
  'https://www.gema.de/portal/app/recovery/password/forgotten';

export const gemaSoundfileMainUrl = 'https://soundfile.gema.de';
export const gemaWorkNumRegisterUrl =
  'https://www.gema.de/portal/app/werkmeldung';

export const OKTA_FIREBASE_ERROR_QUERY_STRING = 'okta-firebase-error';

/**
 * Ts and Cs
 */
export const termsUrl = {
  ['de' as string]: 'https://www.music-hub.com/de/agb-general',
  ['en' as string]: 'https://www.music-hub.com/en/terms-general',
};

export const gemaTermsUrl = {
  ['de' as string]: 'https://www.music-hub.com/de/agb-gema',
  ['en' as string]: 'https://www.music-hub.com/en/terms-gema',
};

export const landingPageUrl = {
  ['de' as string]: 'https://www.music-hub.com/de/home',
  ['en' as string]: 'https://www.music-hub.com/en/home',
};

export const ssoHelpUrl = {
  ['de' as string]: 'https://intercom.help/music-hub/de/articles/5898692-ich-kann-mich-als-gema-mitglied-nicht-bei-musichub-anmelden',
  ['en' as string]: 'https://intercom.help/music-hub/en/articles/5898692-i-can-t-log-in-to-musichub-as-a-gema-member',
};

export const termsIframeNonGemaUrl = {
  ['de' as string]: 'https://www.music-hub.com/de/legal/terms-iframe-general',
  ['en' as string]: 'https://www.music-hub.com/en/legal/terms-iframe-general',
};
export const termsIframeGemaUrl = {
  ['de' as string]: 'https://www.music-hub.com/de/legal/terms-iframe-gema',
  ['en' as string]: 'https://www.music-hub.com/en/legal/terms-iframe-gema',
};

export const lyricsSpecialTerms = {
  ['en' as string]: 'https://www.music-hub.com/legal/special-terms-and-conditions-lyrics',
  ['de' as string]: 'https://www.music-hub.com/legal/besondere-geschaftsbedingungen-fur-songtexte',
};

export const lyricsGemaSpecialTerms = {
  ['en' as string]: 'https://www.music-hub.com/legal/special-terms-and-conditions-lyrics-for-gema-members',
  ['de' as string]: 'https://www.music-hub.com/legal/besondere-geschaftsbedingungen-fur-songtexte-von-gema-mitgliedern',
};

export const uploadCertificateSpecialTerms = {
  ['en' as string]: 'https://www.music-hub.com/legal/special-terms-and-conditions-upload-certificate',
  ['de' as string]: 'https://www.music-hub.com/legal/besondere-geschaftsbedingungen-fur-upload-zertifikat',
};

export const lyricsPlatformsList = {
  ['en' as string]: 'https://intercom.help/music-hub/en/articles/6515516-lyrics-distribution-list',
  ['de' as string]: 'https://intercom.help/music-hub/de/articles/6515516-lyrics-distributionsliste',
};

export const multiUserBetaFeatureUrl = {
  ['en' as string]: 'https://intercom.help/music-hub/en/articles/9881223-collaboration-features',
  ['de' as string]: 'https://intercom.help/music-hub/de/articles/9881223-kollaborations-features-funktionen',
};

export const variousArtistsUrl = {
  ['en' as string]: 'https://intercom.help/music-hub/en/articles/10007038-how-to-create-or-avoid-a-various-artists-release',
  ['de' as string]: 'https://intercom.help/music-hub/de/articles/10007038-warum-steht-bei-meinem-release-der-zusatz-verschiedene-interpret-innen-oder-various-artists',
};

export const linkfireUrl = 'https://www.linkfire.com/';

export const releaseStatusLocked = (
  releaseStatus?: ReleaseCombinedStatus
): boolean =>
  releaseStatus === 'PENDING' ||
  releaseStatus === 'IN_REVIEW' ||
  releaseStatus === 'APPROVED' ||
  releaseStatus === 'AVAILABLE' ||
  releaseStatus === 'LIVE';

/**
 * TYPEFORM
 */

export const withdrawTypeformUrl = {
  ['de' as string]: 'https://music-hub.typeform.com/to/lDPXO6Oo',
  ['en' as string]: 'https://music-hub.typeform.com/to/bxqQEZjD',
};

export const STATS_TIMEFRAMES = {
  '7d': 6,
  '28d': 27,
  '3m': 3,
  '6m': 6,
  '12m': 12,
};
export const DEFAULT_TIMEFRAME = STATS_TIMEFRAMES['7d'];
export const TIMEFRAME_BUFFER = 2;
export const DEFAULT_START_DATE = dayjs()
  .subtract(TIMEFRAME_BUFFER, 'd')
  .subtract(DEFAULT_TIMEFRAME, 'd')
  .startOf('d');
export const DEFAULT_END_DATE = dayjs()
  .subtract(TIMEFRAME_BUFFER, 'd')
  .startOf('d');
export const ALL_TIME_START_DATE = dayjs('8/1/2020').startOf('d');
export const REWIND_START_DATE = dayjs('1/1/2024').startOf('d');
export const REWIND_END_OF_YEAR = dayjs('12/31/2024').endOf('d');
export const REWIND_END_DATE = dayjs().isBefore(REWIND_END_OF_YEAR)
  ? dayjs().startOf('d')
  : REWIND_END_OF_YEAR;
export const ANALYTICS_DATA_START = '2020-01-01T00:00:00';

interface AudienceAgeGroupType {
  total: number;
  male: number;
  female: number;
  label: string;
}
export type AudienceDemographicsType = Record<
  | '0To17'
  | '18To22'
  | '23To27'
  | '28To34'
  | '35To44'
  | '45To59'
  | 'GT59'
  | 'TotalKnown'
  | 'GenderUnknown'
  | 'AgeUnknown',
  AudienceAgeGroupType
>;
export const audienceAgeGenderLabels: AudienceDemographicsType = {
  '0To17': {
    total: 0,
    male: 0,
    female: 0,
    label: '<18',
  },
  '18To22': {
    total: 0,
    male: 0,
    female: 0,
    label: '18-22',
  },
  '23To27': {
    total: 0,
    male: 0,
    female: 0,
    label: '23-27',
  },
  '28To34': {
    total: 0,
    male: 0,
    female: 0,
    label: '28-34',
  },
  '35To44': {
    total: 0,
    male: 0,
    female: 0,
    label: '35-44',
  },
  '45To59': {
    total: 0,
    male: 0,
    female: 0,
    label: '45-59',
  },
  GT59: {
    total: 0,
    male: 0,
    female: 0,
    label: '60+',
  },
  TotalKnown: {
    total: 0,
    male: 0,
    female: 0,
    label: 'Total',
  },
  GenderUnknown: {
    total: 0,
    male: 0,
    female: 0,
    label: 'GenderUnknown',
  },
  AgeUnknown: {
    total: 0,
    male: 0,
    female: 0,
    label: 'AgeUnknown',
  },
};

export const DELIVERY_LEAD_TIME_DAYS = {
  nonClassical: 14,
  classical: 28,
};

/**
 * EXTERNAL NAV/FOOTER LINKS
 */

export const musicHubAcademyLink = 'https://www.music-hub.com/en/academy';
export const musicHubBlogLink = {
  ['de' as string]: 'https://www.music-hub.com/de/blog',
  ['en' as string]: 'https://www.music-hub.com/en/blog',
};
export const SocialInstaLink = 'https://www.instagram.com/joinmusichub/';
export const SocialFacebookLink = 'https://www.facebook.com/joinmusichub.de';
export const SocialLinkedinLink =
  'https://www.linkedin.com/company/musichub-gmbh/mycompany/';
export const SocialYTLink =
  'https://www.youtube.com/channel/UCWABUvm5kI4q-ge8BhDVTag';

/*
 * SUBSCRIPTIONS
 */

export enum BillingCycleType {
  MONTHLY = 'MONTHLY',
  ANNUAL = 'ANNUAL',
}

export function getPlanValue(
  billingCycle: BillingCycleType,
  subscriptionPlan = SubscriptionPlan.BASIC
) {
  const planValues: { [key: string]: number } = {
    BASIC_MONTHLY: 5,
    BASIC_ANNUAL: 48,
    ESSENTIAL_ANNUAL: 59,
    LITE_ANNUAL: 19,
  };
  return planValues[`${subscriptionPlan}_${billingCycle}`] || 0;
}

/**
 * DATES
 */

export const GLOBAL_DATE_FORMAT = 'DD MMM YYYY';

/**
 * BALANCE / PAYMENTS CONSTANTS
 */

export const MINIMUM_TRANFER_AMOUNT = 5;

export const VISUAL_BRAND_ACADEMY_YT =
  'https://www.youtube.com/watch?v=nzA5olwrS50';
