import {
  Track,
  TrackParticipant,
  TrackParticipants,
  TrackAudioRequest,
  TrackRequest,
  TrackParticipantRequest,
} from 'models/Track';
import { flattenParticipants } from 'pages/release-edit/release-edit-form/ReleaseEditForm.helper';
import dayjs from 'dayjs';

const toTitleRequestData = (
  title?: Track['title']
): Track['title'] | undefined => {
  if (!title) return;
  const { version, language } = title;

  if (!title.title && !version && !language) return;

  const requestData = {
    title: title.title,
    language,
    ...(version && { version }),
  };

  return requestData;
};

export const toParticipantRequestData = (
  participant: TrackParticipant | undefined,
  position: number
): TrackParticipantRequest | undefined => {
  const { name, role, participantId } = participant || {};

  if (!role || (!name && !participantId)) return;

  const requestData: TrackParticipantRequest = {
    name,
    role,
    position,
    participantId,
  };

  return requestData;
};

const toParticipantsListRequestData = (
  participants: TrackParticipants
): TrackParticipantRequest[] | undefined => {
  if (participants.length === 0) return;
  let idx = 1;

  const requestData: TrackParticipantRequest[] = participants.reduce(
    (acc: TrackParticipantRequest[], participant) => {
      const participantData = toParticipantRequestData(participant, idx);
      if (participantData) {
        idx++;
        acc.push(participantData);
      }
      return acc;
    },
    []
  );

  return requestData;
};

export const toCopyrightRequestData = (
  copyright: Track['recordingRight'] | undefined
): TrackRequest['recordingRight'] | undefined => {
  if (!copyright) return;
  return {
    name: copyright.name || '',
  };
};

export const toTrackAudioRequestData = (
  releaseFile?: Track['audio']
): TrackAudioRequest | undefined => {
  if (!releaseFile) return;
  const { name, fileName, size, sampleSizeBits, durationSeconds } = releaseFile;

  if (!name || !fileName) return;

  const requestData = {
    storageFileName: fileName,
    originalFileName: name,
    fileSizeInBytes: size,
    sampleSizeBits: sampleSizeBits,
    durationSeconds: durationSeconds,
  };

  return requestData;
};

export const toTrackRequestData = (
  track: Track,
  // TODO: This param ensures that no breaking changes are introduced with the new Track views
  performDefinedSchemaChecks = true
): TrackRequest | undefined => {
  const {
    title,
    participants,
    genre1,
    genre2,
    audio,
    recordingRight,
    language,
    isrc,
    iswc,
    explicit,
    liveRecording,
    originCountry,
    originalReleaseDate,
    preListeningStart,
    instrumental,
    artificialIntelligence,
  } = track;

  if (
    performDefinedSchemaChecks &&
    (!title ||
      !participants ||
      !genre1 ||
      !audio ||
      !language ||
      !recordingRight ||
      !originCountry)
  ) {
    return;
  }

  const titleData = toTitleRequestData(title);
  const participantsData = toParticipantsListRequestData(
    flattenParticipants(track)
  );
  const genre1Data = genre1;
  const genre2Data = genre2;
  const audioData = toTrackAudioRequestData(audio);
  const languageData = language;
  const recordingRightData = toCopyrightRequestData(recordingRight);
  const instrumentalData = Boolean(instrumental);
  const isrcData = isrc;
  const iswcData = iswc;
  const explicitData = explicit === undefined ? false : explicit;
  const liveRecordingData = liveRecording === undefined ? false : liveRecording;
  const originCountryData = originCountry;
  const originalReleaseDateData =
    originalReleaseDate && originalReleaseDate.format('YYYY-MM-DD');
  const preListeningStartData = preListeningStart?.isValid()
    ? preListeningStart?.diff(dayjs().startOf('day'), 'seconds')
    : 0;

  // Required by api
  if (
    performDefinedSchemaChecks &&
    (!titleData ||
      !participantsData ||
      !genre1Data ||
      !audioData ||
      !languageData ||
      !recordingRightData ||
      instrumentalData === undefined ||
      liveRecordingData === undefined ||
      explicitData === undefined ||
      !originCountryData)
  ) {
    return;
  }

  const requestData: TrackRequest = {
    title: titleData,
    participants: participantsData,
    genre1: genre1Data,
    ...(genre2Data && { genre2: genre2Data }),
    audio: audioData,
    language: languageData,
    isrc: isrcData,
    iswc: iswcData,
    recordingRight: recordingRightData,
    instrumental: instrumentalData,
    liveRecording: liveRecordingData,
    explicit: explicitData,
    originCountry: originCountryData,
    ...(originalReleaseDateData && {
      originalReleaseDate: originalReleaseDateData,
    }),
    preListeningStart: preListeningStartData,
    artificialIntelligence,
  };

  return requestData;
};
