export const ACADEMY_PROMO_COURSE_LINK =
  'https://www.music-hub.com/academy-lessons/pre-promotion';
export const ACADEMY_BRANDING_COURSE_LINK =
  'https://www.music-hub.com/academy-lessons/music-branding-strengthen-your-written-identity';

export const SMART_LINKS_INTERCOM = {
  ['en' as string]: 'https://intercom.help/music-hub/en/articles/6310065-what-are-smart-links-and-how-do-they-work',
  ['de' as string]: 'https://intercom.help/music-hub/de/articles/6310065-was-sind-smart-links-und-wie-funktionieren-sie',
};

export const LYRICS_INTERCOM = {
  ['en' as string]: 'https://intercom.help/music-hub/en/articles/6495706-lyrics-distribution',
  ['de' as string]: 'https://intercom.help/music-hub/de/articles/6495706-lyrics-distribution ',
};

export const MONESK_YOUTUBE = 'https://www.youtube.com/watch?v=JPQgazrXbF8';
export const CAXXIANNE_YOUTUBE = 'https://www.youtube.com/watch?v=FvAkPdOxCCQ';
export const SESSION_YOUTUBE = 'https://www.youtube.com/watch?v=oz6U7fZlFW0';

export const RELEASE_PLAN_LINK = {
  ['en' as string]: 'https://www.music-hub.com/en/release-plan?utm_source=inapp&utm_medium=link',
  ['de' as string]: 'https://www.music-hub.com/de/release-plan?utm_source=inapp&utm_medium=link',
};

export const AURALE_TYPEFORM = {
  ['en' as string]: 'https://music-hub.typeform.com/to/gPvJMMze',
  ['de' as string]: 'https://music-hub.typeform.com/to/dgAtTcP7',
};

export const GROOVER_LINK = 'https://try.groover.co/musichub/';

export const SUBMITHUB_LINK = 'https://www.submithub.com/partner/musichub';

export const UNHURD_LINK = 'https://go.unhurd.co.uk/musichub';

export const PRINZ_LINK = 'https://www.prinzstudios.com/';
