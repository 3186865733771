import useSWR, { SWRResponse, SWRConfiguration } from 'swr';
import { AxiosError } from 'axios';
import { SubscriptionResponse, PaymentMethod } from 'models/Subscription';
import { MusicHubUser } from 'hooks/useAuth';
import { mixpanel } from 'tracking/tracking';
import subscriptionService from 'features/subscriptions/services/subscriptionService';

export interface UseSubscriptionReturn
  extends Pick<
    SWRResponse<SubscriptionResponse, AxiosError>,
    'error' | 'revalidate'
  > {
  isLoading: boolean;
  subscription?: SubscriptionResponse;
}

const setSubscriptionToMixpanel = (
  subscription: SubscriptionResponse
): SubscriptionResponse => {
  const paymentType = subscription.paymentMethod?.card
    ? PaymentMethod.CARD
    : subscription.paymentMethod?.sepa
    ? PaymentMethod.SEPA
    : 'NA';
  mixpanel.setPeopleProperty('SUBSCRIPTION_PLAN', subscription.plan);
  mixpanel.setPeopleProperty('SUBSCRIPTION_STATUS', subscription.status);
  mixpanel.setPeopleProperty('SUBSCRIPTION_PAYMENT_METHOD', paymentType);
  mixpanel.setPeopleProperty(
    'SUBSCRIPTION_BILLING_CYLCE',
    subscription.billingCycle
  );
  return subscription;
};

export const useSubscription = (
  user: MusicHubUser | null,
  options?: SWRConfiguration
): UseSubscriptionReturn => {
  const { data: subscription, error, revalidate } = useSWR<
    SubscriptionResponse | undefined,
    AxiosError
  >(
    ['/payments/subscriptions', user],
    () =>
      user !== null
        ? subscriptionService
            .getSubscription(user)
            .then(setSubscriptionToMixpanel)
        : undefined,
    { revalidateOnFocus: false, ...options }
  );

  return {
    subscription: subscription,
    isLoading: !error && !subscription,
    error,
    revalidate,
  };
};
